export const reviews = [
    {
        id: 1,
        name: "Manav Verma",
        date: "1 day ago",
        image: "https://lh3.googleusercontent.com/a/ACg8ocJatwL8_irMwuyHBScTSXIWOA_NHugzs6gImgXAM5OmO4pq9Q=w36-h36-p-rp-mo-br100",
        rating: 5,
        review: `${process.env.REACT_APP_BUSINESS_NAME} is the ultimate shopping destination for fashion! From stylish shoes to quality belts, they have it all. Friendly staff, easy shopping experience, and fast delivery. Highly recommend for anyone looking for top-notch clothing and accessories!`
    },
    {
        id: 2,
        name: "Nisha Rawat",
        date: "1 day ago",
        image: "https://lh3.googleusercontent.com/a/ACg8ocKaL_Ty63cRYIBQH3t0WyWpl8zO8YfXeKdOl4AXfvi6ZBuc5Q=w36-h36-p-rp-mo-br100",
        rating: 5,
        review: `${process.env.REACT_APP_BUSINESS_NAME} is simply amazing! I found all my favorite shoe and belt brands here. The staff was incredibly welcoming and knowledgeable, making my shopping experience effortless.`
    },
    {
        id: 3,
        name: "Kiran Rawat",
        date: "1 day ago",
        image: "https://lh3.googleusercontent.com/a-/ALV-UjXhFLJ4YJvqfTseY6mP4jFhLFRz_-5Hy7VftJz8m7YQGIeLTHY=w36-h36-p-rp-mo-br100",
        rating: 5,
        review: `${process.env.REACT_APP_BUSINESS_NAME} is a dream come true for fashion lovers like me! They have an incredible selection of shoes, belts, and clothing, all at prices that won't break the bank. The staff was super friendly and helpful, making my shopping experience a pleasure. ${process.env.REACT_APP_BUSINESS_NAME} has definitely earned my loyalty as the go-to spot for stylish accessories!`
    },
    {
        id: 5,
        name: "Zaid Khan",
        date: "1 day ago",
        image: "https://lh3.googleusercontent.com/a-/ALV-UjVwBqMVy_pcC1Anf5NP2I0i0UT8lPfzQho172ODNXPdQsnLGQ9I=w36-h36-p-rp-mo-br100",
        rating: 5,
        review: `Absolutely thrilled with my ${process.env.REACT_APP_BUSINESS_NAME} experience! Found all my favorite brands at fantastic prices. The staff was super helpful, and my online order was delivered on time. ${process.env.REACT_APP_BUSINESS_NAME} is now my go-to for top-quality clothing and accessories!`
    },
    {
        id: 6,
        name: "Avinash Jha",
        date: "1 day ago",
        image: "https://lh3.googleusercontent.com/a-/ALV-UjVCGA_5Ye68OglcKE_ClzE6i7ajS_93JV_IGLPlsWrjJQelpRwu=w36-h36-p-rp-mo-ba4-br100",
        rating: 5,
        review: `${process.env.REACT_APP_BUSINESS_NAME} is a treasure trove for fashion enthusiasts! As someone who loves well-crafted shoes and belts, I was impressed by the extensive collection of branded items at great prices. ${process.env.REACT_APP_BUSINESS_NAME} exceeded all my expectations!`
    },
];

export const specials = {
    "topBrands": [
        {
            "name": "Hot Wheels",
            "url": "/store-product/brand/2300"
        },
        {
            "name": "Nerf",
            "url": "/store-product/brand/2497"
        },
        {
            "name": "Barbie",
            "url": "/store-product/brand/barbie"
        },
        {
            "name": "Lego",
            "url": "/store-product/brand/lego"
        },
        {
            "name": "Play Shifu",
            "url": "/store-product/brand/playshifu"
        },
        {
            "name": "Smartivity",
            "url": "/store-product/brand/smartivity"
        },
        {
            "name": "Miko",
            "url": "/store-product/brand/miko"
        },
        {
            "name": "Funskool",
            "url": "/store-product/brand/funskool"
        },
        {
            "name": "Sameo",
            "url": "/store-product/brand/sameo"
        },
        {
            "name": "Hasbro",
            "url": "/store-product/brand/hasbro"
        },
        {
            "name": "Giggles",
            "url": "/store-product/brand/giggles"
        },
        {
            "name": "Majorette",
            "url": "/store-product/brand/majorette"
        },
        {
            "name": "Jada Toys",
            "url": "/store-product/brand/jada"
        },
    ],

    "mainHeading": `${process.env.REACT_APP_BUSINESS_NAME}'s Toy Store - India's most loved place to buy toys for children!`,

    "mainHeadingDescription": `${process.env.REACT_APP_BUSINESS_NAME}'s Toy Store is renowned as India's favorite destination for purchasing children's toys. It offers a wide variety of high-quality, engaging, and fun toys that cater to kids of all ages. Parents and children alike love the store for its excellent customer service, diverse selection, and commitment to bringing joy to playtime.`,

    "subHeading": `Why ${process.env.REACT_APP_BUSINESS_NAME}'s Toy Store?`,

    "subHeadingImpPoints": [
        {
            "heading": "Quality Assurance:",
            "description": "Our toys undergo rigorous testing to ensure they are safe, durable, and meet the highest quality standards."
        },
        {
            "heading": "Expertise:",
            "description": "Our team consists of toy enthusiasts and professionals who bring years of experience to provide the best play experiences for your children."
        },
        {
            "heading": "Innovative Solutions:",
            "description": "From interactive toys to educational games, we offer a wide selection of products designed to keep your kids engaged, learning, and having fun."
        },
        {
            "heading": "Customer Satisfaction:",
            "description": "Your satisfaction is our priority. We strive to provide exceptional service and support, ensuring that every shopping experience is enjoyable for both you and your children."
        },
        {
            "heading": "Convenience:",
            "description": "Shop online or visit our store by searching for a toy store near me. We offer the best toy solutions for your convenience."
        },

    ]
}

export const aboutUs = {
    "heading": `About ${process.env.REACT_APP_BUSINESS_NAME} &ndash; Your Premium Toy Destination`,

    "description": `${process.env.REACT_APP_BUSINESS_NAME} is where the joy of childhood comes alive. We&#39;re a premium toy store dedicated to bringing the world&#39;s best playthings to your doorstep. Whether you&#39;re searching for a classic wooden puzzle, the latest tech-powered robot, or a beloved character from your favorite story, you&#39;ll find it at ${process.env.REACT_APP_BUSINESS_NAME}.`,

    "keyDifference": `The ${process.env.REACT_APP_BUSINESS_NAME} Difference`,

    "keyDifferencePoints": [
        {
            "heading": "Curated Collection:",
            "description": "&nbsp;We carefully handpick toys from around the world and India, ensuring a mix of both internationally renowned brands and exceptional local craftsmanship."
        },
        {
            "heading": "Premium Experience:",
            "description": "&nbsp;From the moment you explore our website to the day your little one unwraps their new treasure, we strive to provide a delightful and seamless shopping experience."
        },
        {
            "heading": "Passion for Play:",
            "description": "&nbsp;Our founder, Varun Behl, is a toy enthusiast at heart. His dynamic vision drives {process.env.REACT_APP_BUSINESS_NAME}&#39;s mission to make us the preferred destination for all things toys in India."
        }
    ],

    "otherInfo": `<p>Discover the Wonders of Play</p><p> Come explore the endless possibilities of imagination and creativity at {process.env.REACT_APP_BUSINESS_NAME}.</p><p> Let us help you find the perfect toy that will spark joy and wonder in your child&#39;s life!</p>`
}