export default function NoProductFound() {
    return <div style={{
        height: "60vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }}>
        <h2 style={{
            fontSize: "2rem",
        }}>
            No Products Found
        </h2>
    </div>
}